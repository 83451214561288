.homepage h1 {
  color: var(--tuscany);
}

.homepage h2 {
  color: var(--rust);
}

.homepage h3 {
  color: var(--misty-rose);
}

#kat {
  transition: all 0.5s ease-in-out 0s;
  color: var(--snow);
}

#portland {
  color: var(--snow);
}

#kat:hover {
  color: var(--persian-plum);
}


.homepage a {
  font-size: inherit;
  color: var(--chocolate-web);
  text-decoration: none;
  opacity: .8;
  letter-spacing: 1px;
  transition: all 0.5s ease-in-out 0s;
}



/* desktop */
@media (min-width: 600px) {
  .homepage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100vw;
    text-align: center;
    margin-top: 8vh;
  }

  .home-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: fit-content;
  }

  .homepage h1 {
    font-size: 7.5rem;
    padding: 0 30px;
    font-weight: 700;
    margin: 10px 0 20px;
  }

  .homepage h2 {
    font-size: 3.5rem;
    padding: 0 50px;
    font-weight: 600;
    max-width: 750px;
    margin: 40px 0;
  }
  
  .homepage h3 {
    max-width: 600px;
    padding: 0 100px;
    font-size: 1.9rem;
    font-weight: 400;
    margin: 10px 0 50px;
  }

  .homepage a:hover {
    padding-bottom: 3px;
    opacity: 1;
    /* letter-spacing: 5px; */
  }
}

/* mobile */
@media (max-width: 600px) {
  .homepage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100vw;
    height: 100vh;
    text-align: center;
  }

  .home-content {
    height: 85%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  
  .homepage h1 {
    text-align: left;
    width: 335px;
    font-size: 5.4rem;
    font-weight: 600;
    margin: 10px 20px;
  }
  
  .homepage h2 {
    width: 335px;
    font-size: 2.9rem;
    margin: 20px 20px;
    font-weight: 600;
    text-align: right;
  }

  .homepage h3 {
    display: none;
  }
}









