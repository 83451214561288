.about-text {
  text-align: left;
}

.about-text h1 {
  color: var(--misty-rose);
  opacity: .95;
}

#pronouns {
  color: var(--tuscany);
  opacity: .7;
}

.about-text p {
  color: var(--misty-rose);
  text-align: left;
  opacity: .95;
}

strong {
  color: var(--redwood);
  opacity: .7;
  letter-spacing: 2px;
}

.about-page img {
  filter: brightness(110%);
  transition: all 1s ease;
}

.about-page img:hover {
  opacity: .5;
  filter: grayscale(100%);
}


/* desktop */
@media (min-width: 600px) {
  .about-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 90vw;
    padding: 50px 0 100px;
  }

  .about-content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
    align-content: center;
  }
  
  .about-page img {
    opacity: .9;
    width: 450px;
    border-radius: 50%;
    box-shadow: 0 0 30px black;
  }
  
  .about-text {
    width: 450px;
    margin: 5px 10px;
    text-align: left;
    padding: 20px;
  }
  
  .about-text h1 {
    font-size: 4rem;
    font-weight: 700;
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    align-items: baseline;
  }
  
  #pronouns {
    font-size: 1.9rem;
    margin: 0 0 5px 10px;
  }
  
  .about-text p {
    font-size: 1.4rem;
    padding: 0px;
    margin: 20px 0 0;
  }

}

/* mobile */
@media (max-width: 600px) { 
  .about-page {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: 100vh;
    padding-bottom: 100px;
  }

  .about-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 90%;
  }
  
  .about-page img {
    width: 100vw;
    padding-bottom: 75px;
    opacity: .8;
  }
  
  .about-text h1 {
    font-size: 2.5rem;
    font-weight: 600;
    margin: 0 0 10px;
    display: flex;
    flex-direction: row;
    align-items: baseline;
  }

  .about-text {
    width: 90vw;
    margin: 5px 10px;
    text-align: left;
    padding: 20px;
  }
  
  
  #pronouns {
    font-size: 1.5rem;
    letter-spacing: 1px;
    margin: 0 0 5px 10px;
  }
  
  .about-text p {
    font-size: 1.05rem;
    padding: 0px;
    width: fit-content;
  }

}





