@import url('https://fonts.googleapis.com/css2?family=Anton&family=Antonio&family=Archivo&family=Arvo:wght@400;700&family=Bangers&family=BioRhyme&family=Bungee+Inline&family=Chicle&family=Comfortaa:wght@300&family=Creepster&family=Encode+Sans+Semi+Expanded&family=Epilogue&family=Grand+Hotel&family=Great+Vibes&family=Griffy&family=Heebo:wght@900&family=IM+Fell+French+Canon&family=IM+Fell+French+Canon+SC&family=Inconsolata:wght@200&family=Inter&family=Irish+Grover&family=Josefin+Sans&family=Karla&family=Lato&family=Limelight&family=Manrope&family=Montserrat+Subrayada:wght@400;700&family=Montserrat:wght@400;500;600;700&family=Neonderthaw&family=Nixie+One&family=Oswald&family=Philosopher:wght@400;700&family=Poiret+One&family=Poppins&family=Press+Start+2P&family=Raleway:wght@100&family=Roboto+Mono:wght@100&family=Rubik+Wet+Paint&family=Rye&family=Silkscreen&family=Slabo+27px&family=Sora:wght@400;700&family=Space+Grotesk&family=Space+Mono&family=Spectral&family=Syne&family=Tenor+Sans&family=Ultra&family=Unica+One&family=VT323&family=Varela+Round&family=Yeseva+One&display=swap');

* {
  --persian-plum: #6E1D1Dff;
  --falu-red: #791A1Aff;
  --redwood: #AE624Eff;
  --rust: #B64200ff;
  --chocolate-web: #CA7141ff;
  --tuscany: #B49A9Aff;
  --misty-rose: #FEEBE8ff;
  --snow: #FFFAF9ff;
}

body::-webkit-scrollbar {
  display: none;
}

html, body {
  font-family: 'Montserrat', sans-serif;
  min-height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  background: linear-gradient( rgba(0, 0, 0, 0.92), rgba(0, 0, 0, 0.92) ), url('./capelookout.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  scroll-behavior: smooth;

}

body {
  margin: 0;
}

.App {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: fit-content;
  margin: 50px;
}


/* navigation header */

nav {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  height: 5em;
  width: 100%;
}

.nav-bar {
  list-style-type: none;
  margin: 20px;
  display: none;
  width: 80vw;
  max-width: 1200px;
  padding: 0;
  right: 50;
  top: 12px;
  position: absolute;
  display: none;
}

nav img {
  width: 25px;
  filter: invert(100%);
  opacity: .9;
  
}

#project-icon {
  width: 17px;
}


  @keyframes slide-in {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0%);
  }
}

@keyframes wipe-enter {
	0% {
		transform: scale(0, .025);
	}
	50% {
		transform: scale(1, .025);
	}
}



  .burger {
    display: block;
  }

@media (min-width: 760px) {

  .nav-bar.expanded  {
    animation: slide-in 1s ease-in-out;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
  }



  nav {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 5em;
    width: 100%;
  }

  .nav-icon {
    display: none;
  }

  nav a {
    margin: 0 5px;

  }

}

@media (max-width: 759px) {

  .nav-bar.expanded  {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
  }

  nav {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    height: 5em;
    width: 100%;
  }

  .nav-text {
    display: none;
    
  }

  nav a {
    margin: 0 8px;
  }


}


.active {
  color: var(--redwood);
  cursor: default;
  transition: all 0.5s ease-in-out 0s;
  letter-spacing: 2px;
}

.active img {
  opacity: .3;
}

nav a {
  color: var(--snow);
  text-decoration: none;
  transition: all 0.5s ease-in-out 0s;
  font-size: 1.35rem;
}

nav a:not(.active):hover {
  opacity: .7;
  padding-bottom: 4px;
  border-bottom: 1.5px solid var(--snow);
  color: var(--rust);
}

.burger {
  border: 0;
  height: 60px;
  width: 60px;
  padding: 0.5rem;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  position: absolute;
  top: 12px;
  right: 10px;
  background-color: rgba(255, 255, 255, 0);
  opacity: .9;
}

.burger:hover {
  opacity: .5;
}

