@import url(https://fonts.googleapis.com/css2?family=Anton&family=Antonio&family=Archivo&family=Arvo:wght@400;700&family=Bangers&family=BioRhyme&family=Bungee+Inline&family=Chicle&family=Comfortaa:wght@300&family=Creepster&family=Encode+Sans+Semi+Expanded&family=Epilogue&family=Grand+Hotel&family=Great+Vibes&family=Griffy&family=Heebo:wght@900&family=IM+Fell+French+Canon&family=IM+Fell+French+Canon+SC&family=Inconsolata:wght@200&family=Inter&family=Irish+Grover&family=Josefin+Sans&family=Karla&family=Lato&family=Limelight&family=Manrope&family=Montserrat+Subrayada:wght@400;700&family=Montserrat:wght@400;500;600;700&family=Neonderthaw&family=Nixie+One&family=Oswald&family=Philosopher:wght@400;700&family=Poiret+One&family=Poppins&family=Press+Start+2P&family=Raleway:wght@100&family=Roboto+Mono:wght@100&family=Rubik+Wet+Paint&family=Rye&family=Silkscreen&family=Slabo+27px&family=Sora:wght@400;700&family=Space+Grotesk&family=Space+Mono&family=Spectral&family=Syne&family=Tenor+Sans&family=Ultra&family=Unica+One&family=VT323&family=Varela+Round&family=Yeseva+One&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.homepage h1 {
  color: var(--tuscany);
}

.homepage h2 {
  color: var(--rust);
}

.homepage h3 {
  color: var(--misty-rose);
}

#kat {
  transition: all 0.5s ease-in-out 0s;
  color: var(--snow);
}

#portland {
  color: var(--snow);
}

#kat:hover {
  color: var(--persian-plum);
}


.homepage a {
  font-size: inherit;
  color: var(--chocolate-web);
  text-decoration: none;
  opacity: .8;
  letter-spacing: 1px;
  transition: all 0.5s ease-in-out 0s;
}



/* desktop */
@media (min-width: 600px) {
  .homepage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100vw;
    text-align: center;
    margin-top: 8vh;
  }

  .home-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }

  .homepage h1 {
    font-size: 7.5rem;
    padding: 0 30px;
    font-weight: 700;
    margin: 10px 0 20px;
  }

  .homepage h2 {
    font-size: 3.5rem;
    padding: 0 50px;
    font-weight: 600;
    max-width: 750px;
    margin: 40px 0;
  }
  
  .homepage h3 {
    max-width: 600px;
    padding: 0 100px;
    font-size: 1.9rem;
    font-weight: 400;
    margin: 10px 0 50px;
  }

  .homepage a:hover {
    padding-bottom: 3px;
    opacity: 1;
    /* letter-spacing: 5px; */
  }
}

/* mobile */
@media (max-width: 600px) {
  .homepage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100vw;
    height: 100vh;
    text-align: center;
  }

  .home-content {
    height: 85%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  
  .homepage h1 {
    text-align: left;
    width: 335px;
    font-size: 5.4rem;
    font-weight: 600;
    margin: 10px 20px;
  }
  
  .homepage h2 {
    width: 335px;
    font-size: 2.9rem;
    margin: 20px 20px;
    font-weight: 600;
    text-align: right;
  }

  .homepage h3 {
    display: none;
  }
}










.about-text {
  text-align: left;
}

.about-text h1 {
  color: var(--misty-rose);
  opacity: .95;
}

#pronouns {
  color: var(--tuscany);
  opacity: .7;
}

.about-text p {
  color: var(--misty-rose);
  text-align: left;
  opacity: .95;
}

strong {
  color: var(--redwood);
  opacity: .7;
  letter-spacing: 2px;
}

.about-page img {
  -webkit-filter: brightness(110%);
          filter: brightness(110%);
  transition: all 1s ease;
}

.about-page img:hover {
  opacity: .5;
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
}


/* desktop */
@media (min-width: 600px) {
  .about-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 90vw;
    padding: 50px 0 100px;
  }

  .about-content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
    align-content: center;
  }
  
  .about-page img {
    opacity: .9;
    width: 450px;
    border-radius: 50%;
    box-shadow: 0 0 30px black;
  }
  
  .about-text {
    width: 450px;
    margin: 5px 10px;
    text-align: left;
    padding: 20px;
  }
  
  .about-text h1 {
    font-size: 4rem;
    font-weight: 700;
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    align-items: baseline;
  }
  
  #pronouns {
    font-size: 1.9rem;
    margin: 0 0 5px 10px;
  }
  
  .about-text p {
    font-size: 1.4rem;
    padding: 0px;
    margin: 20px 0 0;
  }

}

/* mobile */
@media (max-width: 600px) { 
  .about-page {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: 100vh;
    padding-bottom: 100px;
  }

  .about-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 90%;
  }
  
  .about-page img {
    width: 100vw;
    padding-bottom: 75px;
    opacity: .8;
  }
  
  .about-text h1 {
    font-size: 2.5rem;
    font-weight: 600;
    margin: 0 0 10px;
    display: flex;
    flex-direction: row;
    align-items: baseline;
  }

  .about-text {
    width: 90vw;
    margin: 5px 10px;
    text-align: left;
    padding: 20px;
  }
  
  
  #pronouns {
    font-size: 1.5rem;
    letter-spacing: 1px;
    margin: 0 0 5px 10px;
  }
  
  .about-text p {
    font-size: 1.05rem;
    padding: 0px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }

}






.project-card {
  text-align: left;
  min-height: -webkit-fit-content;
  min-height: -moz-fit-content;
  min-height: fit-content;
}

.project-card h1 {
  color: var(--redwood);
}

.project-card h3 {
  color: var(--tuscany);
  opacity: 1;
}


.project-card p {
  text-align: left;
  opacity: .9;
  color: var(--snow);
}
  

.project-links {
  border-bottom: 1px solid var(--redwood);
  text-decoration: none;
  transition: all 0.5s ease-in-out 0s;
  color: var(--misty-rose);
}


.printstagram-vid {
  -webkit-filter: invert(100%);
          filter: invert(100%);
}



/* desktop */
@media (min-width: 600px) {
@-webkit-keyframes wipe-enter {
	0% {
		-webkit-transform: scale(0, .025);
		        transform: scale(0, .025);
	}
	50% {
		-webkit-transform: scale(1, .025);
		        transform: scale(1, .025);
	}
}
@keyframes wipe-enter {
	0% {
		-webkit-transform: scale(0, .025);
		        transform: scale(0, .025);
	}
	50% {
		-webkit-transform: scale(1, .025);
		        transform: scale(1, .025);
	}
}

@media (prefers-reduced-motion: no-preference) {
  video, img {
    -webkit-animation: wipe-enter 1s 1;
            animation: wipe-enter 1s 1;
  }
}

  .project-page {
    display: grid;
    grid-template-rows: 90vh 50px 90vh 50px 90vh 50px 90vh 50px;
  }
  
  
  .project-card {
    padding: 5px;
    text-align: left;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: flex-start;
    align-content: center;
    width: 95vw;
    max-width: 1100px;
  }



  #stellarview {
    grid-row: 1 / span 1;
  }

  #boobot {
    grid-row: 3 / span 1;
  }
  
  #printstagram {
    grid-row: 5 / span 1;
  }
  
  #vsssnake {
    grid-row: 7 / span 1;
  }

  .project-card h1 {
    font-size: 3.7rem;
    font-weight: 600;
    padding: 5px 10px 15px;
    margin: 0;
  }

  .project-card h3 {
    font-weight: 400;
    font-size: 1.2rem;
    padding: 20px 10px 10px;
    margin: 0;
  }

  .project-card p {
    font-size: 1.2rem;
    letter-spacing: .5px;
    text-align: left;
    padding: 15px 10px;
    margin: 0;
  }
  
  .project-links {
    margin: 10px 10px;
    padding: 5px 0 3px;
    font-size: 1.5rem;
    opacity: .5;
  }

  .project-links:hover {
    opacity: 1;
    transition: all 0.5s ease-in-out 0s;
    color: var(--persian-plum);
    padding-bottom: 4px;
    border-bottom: .5px solid var(--misty-rose);
  }

  .content {
    margin: 0 20px;
    width: 450px;
  }

  .media {
    width: 450px;
    display: flex;
    flex-direction: row;
    flex-wrap: no-wrap;
    align-items: center;
    justify-content: space-around;
  }

  video {
    width: 98%;
    opacity: .6;
    padding: 10px;
    border-radius: 15px;
  }

  .arrow {
    width: 45px;
    -webkit-filter: invert(100%);
            filter: invert(100%);
    border: none;
    transition: all 0.5s ease-in-out 0s;
    opacity: .6;
  }

  #top-arrow {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }

  .arrows {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 2%;
    height: 150px;
    margin: 20px;
  }

  .arrow:hover {
    padding-bottom: 0;
    border-bottom: 0;
    opacity: .9;
  }

}

/* mobile */
@media (max-width: 600px) {

  .project-page {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 5px 0 100px;
    width: 100vh;
  }

  .arrows {
    display: none;
  }

  .project-card {
    margin: 0px 10px 70px;
    padding: 0 5px 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 90vw;
    border-bottom: .5px solid var(--misty-rose);

  }

  .project-card h1 {
    font-size: 2.5rem;
    font-weight: 600;
    padding: 0;
    margin: 0 0 10px;
  }

  .project-links {
    margin: 7px 10px 7px 0;
    padding: 5px 0 2px;
    font-size: .95rem;
    opacity: .8;
  }

  .project-card h3 {
    font-weight: 400;
    font-size: .8rem;
    padding: 12px 0;
    margin: 0;

  }

  .project-card p {
    font-size: .9rem;
    text-align: left;
    margin: 0;
    opacity: .8;
  }

  .content {
    margin: 20px;
    width: 90vw;
  }

  .media {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  video {
    opacity: .5;
    width: 100vw;
    margin: 40px 0 ;
  }

}









.left-section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.connect-page h1 {
  color: var(--redwood);
  opacity: .9;
  padding-top: 13px;
}

.connect-page p {
  margin: 0px;
  color: var(--misty-rose);
  opacity: .9;

}

.right-section ul {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-evenly;
  width: 100%;
  margin: 0;
  height: 100%;
  border-bottom: 1px solid var(--tuscany);
  
}

li {
  color: var(--redwood);
  opacity: .9;
}

.right-section a {
  color: var(--tuscany);
  text-decoration: none;
  transition: all 0.5s ease-in-out 0s;
  opacity: .7;
}



/* desktop */
@media (min-width: 600px) {

  .connect-page {
    display: flex;
    padding: 20px 0 100px;
    width: 100vw;
    justify-content: center;
    align-items: center;
  }

  .connect-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }

  .left-section {
    padding: 40px;
    height: 320px;
    width: 450px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    
  }
  
  .right-section {
    padding: 40px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 450px;
    height: 320px;
  }
  
  .connect-page h1 {
    font-size: 4rem;
    font-weight: 600;
    margin-bottom: 30px;
    letter-spacing: 2px;
    line-height: 53px;
  }

  .connect-page p {
    margin: 0px;
    line-height: 32px;
    font-weight: 400;
    font-size: 1.5rem;
  }

  .connect-page p span {
    opacity: .5;
  }

  .right-section ul {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    margin: 0;
    height: 100%;
    list-style-type: none;
    padding-top: 17px;
    padding-bottom: 55px;
    padding-right: 0;
    padding-left: 0;
  }
  
  .right-section li {
    text-decoration: none;
    font-size: 2rem;
    font-weight: 500;
    padding: 25px 5px;
    letter-spacing: 2px;
  }


  .right-section a {
    text-decoration: none;
    transition: all 0.5s ease-in-out 0s;
    padding-right: 10px;
  }

  .right-section a:hover {
    opacity: 1;
    transition: all 0.5s ease-in-out 0s;
    color: var(--falu-red);
    /* padding-bottom: 4px; */
    /* border-bottom: 1px solid var(--snow); */
  }
}

/* mobile */
@media (max-width: 600px) {
  .connect-page {
    display: flex;
    height: 90vh;
    width: 100vw;
    justify-content: center;
    align-items: flex-start;
  }

  .left-section {
    padding: 0 30px 30px;
    width: 335px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }

  .right-section {
    padding: 0 30px 30px;
    width: 335px;
  }

  .connect-page h1 {
    font-size: 2.3rem;
    font-weight: 600;
    margin-bottom: 20px;
    width: 335px;
    border-top: 1px solid var(--tuscany);

  }

  .connect-page p {
    margin: 0px;
    line-height: 25px;
    font-weight: 500;
    font-size: 1rem;
  }

  .right-section ul {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-evenly;
    width: 335px;
    margin: 0;
    height: 200px;
    list-style-type: none;
    padding: 0;
  }

  li {
    font-size: 1.2rem;
    font-weight: 500;
  }

  .right-section a {
    color: var(--tuscany);
    text-decoration: none;
    transition: all 0.5s ease-in-out 0s;
    opacity: .7;
  }
}

* {
  --persian-plum: #6E1D1Dff;
  --falu-red: #791A1Aff;
  --redwood: #AE624Eff;
  --rust: #B64200ff;
  --chocolate-web: #CA7141ff;
  --tuscany: #B49A9Aff;
  --misty-rose: #FEEBE8ff;
  --snow: #FFFAF9ff;
}

body::-webkit-scrollbar {
  display: none;
}

html, body {
  font-family: 'Montserrat', sans-serif;
  min-height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  background: linear-gradient( rgba(0, 0, 0, 0.92), rgba(0, 0, 0, 0.92) ), url(/static/media/capelookout.25c1588b.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  scroll-behavior: smooth;

}

body {
  margin: 0;
}

.App {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: 50px;
}


/* navigation header */

nav {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  height: 5em;
  width: 100%;
}

.nav-bar {
  list-style-type: none;
  margin: 20px;
  display: none;
  width: 80vw;
  max-width: 1200px;
  padding: 0;
  right: 50;
  top: 12px;
  position: absolute;
  display: none;
}

nav img {
  width: 25px;
  -webkit-filter: invert(100%);
          filter: invert(100%);
  opacity: .9;
  
}

#project-icon {
  width: 17px;
}


  @-webkit-keyframes slide-in {
  from {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  to {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
}


  @keyframes slide-in {
  from {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  to {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
}

@-webkit-keyframes wipe-enter {
	0% {
		-webkit-transform: scale(0, .025);
		        transform: scale(0, .025);
	}
	50% {
		-webkit-transform: scale(1, .025);
		        transform: scale(1, .025);
	}
}

@keyframes wipe-enter {
	0% {
		-webkit-transform: scale(0, .025);
		        transform: scale(0, .025);
	}
	50% {
		-webkit-transform: scale(1, .025);
		        transform: scale(1, .025);
	}
}



  .burger {
    display: block;
  }

@media (min-width: 760px) {

  .nav-bar.expanded  {
    -webkit-animation: slide-in 1s ease-in-out;
            animation: slide-in 1s ease-in-out;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
  }



  nav {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 5em;
    width: 100%;
  }

  .nav-icon {
    display: none;
  }

  nav a {
    margin: 0 5px;

  }

}

@media (max-width: 759px) {

  .nav-bar.expanded  {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
  }

  nav {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    height: 5em;
    width: 100%;
  }

  .nav-text {
    display: none;
    
  }

  nav a {
    margin: 0 8px;
  }


}


.active {
  color: var(--redwood);
  cursor: default;
  transition: all 0.5s ease-in-out 0s;
  letter-spacing: 2px;
}

.active img {
  opacity: .3;
}

nav a {
  color: var(--snow);
  text-decoration: none;
  transition: all 0.5s ease-in-out 0s;
  font-size: 1.35rem;
}

nav a:not(.active):hover {
  opacity: .7;
  padding-bottom: 4px;
  border-bottom: 1.5px solid var(--snow);
  color: var(--rust);
}

.burger {
  border: 0;
  height: 60px;
  width: 60px;
  padding: 0.5rem;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  position: absolute;
  top: 12px;
  right: 10px;
  background-color: rgba(255, 255, 255, 0);
  opacity: .9;
}

.burger:hover {
  opacity: .5;
}


