

.left-section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.connect-page h1 {
  color: var(--redwood);
  opacity: .9;
  padding-top: 13px;
}

.connect-page p {
  margin: 0px;
  color: var(--misty-rose);
  opacity: .9;

}

.right-section ul {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-evenly;
  width: 100%;
  margin: 0;
  height: 100%;
  border-bottom: 1px solid var(--tuscany);
  
}

li {
  color: var(--redwood);
  opacity: .9;
}

.right-section a {
  color: var(--tuscany);
  text-decoration: none;
  transition: all 0.5s ease-in-out 0s;
  opacity: .7;
}



/* desktop */
@media (min-width: 600px) {

  .connect-page {
    display: flex;
    padding: 20px 0 100px;
    width: 100vw;
    justify-content: center;
    align-items: center;
  }

  .connect-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }

  .left-section {
    padding: 40px;
    height: 320px;
    width: 450px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    
  }
  
  .right-section {
    padding: 40px;
    height: fit-content;
    width: 450px;
    height: 320px;
  }
  
  .connect-page h1 {
    font-size: 4rem;
    font-weight: 600;
    margin-bottom: 30px;
    letter-spacing: 2px;
    line-height: 53px;
  }

  .connect-page p {
    margin: 0px;
    line-height: 32px;
    font-weight: 400;
    font-size: 1.5rem;
  }

  .connect-page p span {
    opacity: .5;
  }

  .right-section ul {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    margin: 0;
    height: 100%;
    list-style-type: none;
    padding-top: 17px;
    padding-bottom: 55px;
    padding-right: 0;
    padding-left: 0;
  }
  
  .right-section li {
    text-decoration: none;
    font-size: 2rem;
    font-weight: 500;
    padding: 25px 5px;
    letter-spacing: 2px;
  }


  .right-section a {
    text-decoration: none;
    transition: all 0.5s ease-in-out 0s;
    padding-right: 10px;
  }

  .right-section a:hover {
    opacity: 1;
    transition: all 0.5s ease-in-out 0s;
    color: var(--falu-red);
    /* padding-bottom: 4px; */
    /* border-bottom: 1px solid var(--snow); */
  }
}

/* mobile */
@media (max-width: 600px) {
  .connect-page {
    display: flex;
    height: 90vh;
    width: 100vw;
    justify-content: center;
    align-items: flex-start;
  }

  .left-section {
    padding: 0 30px 30px;
    width: 335px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }

  .right-section {
    padding: 0 30px 30px;
    width: 335px;
  }

  .connect-page h1 {
    font-size: 2.3rem;
    font-weight: 600;
    margin-bottom: 20px;
    width: 335px;
    border-top: 1px solid var(--tuscany);

  }

  .connect-page p {
    margin: 0px;
    line-height: 25px;
    font-weight: 500;
    font-size: 1rem;
  }

  .right-section ul {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-evenly;
    width: 335px;
    margin: 0;
    height: 200px;
    list-style-type: none;
    padding: 0;
  }

  li {
    font-size: 1.2rem;
    font-weight: 500;
  }

  .right-section a {
    color: var(--tuscany);
    text-decoration: none;
    transition: all 0.5s ease-in-out 0s;
    opacity: .7;
  }
}
