.project-card {
  text-align: left;
  min-height: fit-content;
}

.project-card h1 {
  color: var(--redwood);
}

.project-card h3 {
  color: var(--tuscany);
  opacity: 1;
}


.project-card p {
  text-align: left;
  opacity: .9;
  color: var(--snow);
}
  

.project-links {
  border-bottom: 1px solid var(--redwood);
  text-decoration: none;
  transition: all 0.5s ease-in-out 0s;
  color: var(--misty-rose);
}


.printstagram-vid {
  filter: invert(100%);
}



/* desktop */
@media (min-width: 600px) {
@keyframes wipe-enter {
	0% {
		transform: scale(0, .025);
	}
	50% {
		transform: scale(1, .025);
	}
}

@media (prefers-reduced-motion: no-preference) {
  video, img {
    animation: wipe-enter 1s 1;
  }
}

  .project-page {
    display: grid;
    grid-template-rows: 90vh 50px 90vh 50px 90vh 50px 90vh 50px;
  }
  
  
  .project-card {
    padding: 5px;
    text-align: left;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: flex-start;
    align-content: center;
    width: 95vw;
    max-width: 1100px;
  }



  #stellarview {
    grid-row: 1 / span 1;
  }

  #boobot {
    grid-row: 3 / span 1;
  }
  
  #printstagram {
    grid-row: 5 / span 1;
  }
  
  #vsssnake {
    grid-row: 7 / span 1;
  }

  .project-card h1 {
    font-size: 3.7rem;
    font-weight: 600;
    padding: 5px 10px 15px;
    margin: 0;
  }

  .project-card h3 {
    font-weight: 400;
    font-size: 1.2rem;
    padding: 20px 10px 10px;
    margin: 0;
  }

  .project-card p {
    font-size: 1.2rem;
    letter-spacing: .5px;
    text-align: left;
    padding: 15px 10px;
    margin: 0;
  }
  
  .project-links {
    margin: 10px 10px;
    padding: 5px 0 3px;
    font-size: 1.5rem;
    opacity: .5;
  }

  .project-links:hover {
    opacity: 1;
    transition: all 0.5s ease-in-out 0s;
    color: var(--persian-plum);
    padding-bottom: 4px;
    border-bottom: .5px solid var(--misty-rose);
  }

  .content {
    margin: 0 20px;
    width: 450px;
  }

  .media {
    width: 450px;
    display: flex;
    flex-direction: row;
    flex-wrap: no-wrap;
    align-items: center;
    justify-content: space-around;
  }

  video {
    width: 98%;
    opacity: .6;
    padding: 10px;
    border-radius: 15px;
  }

  .arrow {
    width: 45px;
    filter: invert(100%);
    border: none;
    transition: all 0.5s ease-in-out 0s;
    opacity: .6;
  }

  #top-arrow {
    transform: rotate(90deg);
  }

  .arrows {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 2%;
    height: 150px;
    margin: 20px;
  }

  .arrow:hover {
    padding-bottom: 0;
    border-bottom: 0;
    opacity: .9;
  }

}

/* mobile */
@media (max-width: 600px) {

  .project-page {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 5px 0 100px;
    width: 100vh;
  }

  .arrows {
    display: none;
  }

  .project-card {
    margin: 0px 10px 70px;
    padding: 0 5px 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 90vw;
    border-bottom: .5px solid var(--misty-rose);

  }

  .project-card h1 {
    font-size: 2.5rem;
    font-weight: 600;
    padding: 0;
    margin: 0 0 10px;
  }

  .project-links {
    margin: 7px 10px 7px 0;
    padding: 5px 0 2px;
    font-size: .95rem;
    opacity: .8;
  }

  .project-card h3 {
    font-weight: 400;
    font-size: .8rem;
    padding: 12px 0;
    margin: 0;

  }

  .project-card p {
    font-size: .9rem;
    text-align: left;
    margin: 0;
    opacity: .8;
  }

  .content {
    margin: 20px;
    width: 90vw;
  }

  .media {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  video {
    opacity: .5;
    width: 100vw;
    margin: 40px 0 ;
  }

}






